<template>
    <div v-loading="loading" class="learning-course">
        <div ref="content" class="learn">
            <video ref="player" style="padding:58px 0;display:block;vertical-aligin:top" class="video-js vjs-default-skin vjs-big-play-centered" />
            <div v-if="rightWidthVar===350" :style="{height:screenHeight+'px',left:screenWidth-rightWidthVar+'px'}" class="right-block">
                <div class="right-top">
                    <div class="guanbi"><i class="safety-icon-min" style="cursor:pointer" @click="rightWidthVar=20" /></div>
                    <div :title="courseInfo.name" class="course-name" @click="godetail">{{ courseInfo.title }}</div>
                    <div class="time-share-like">
                        <span class="time">{{ allTime|formatTime }}</span>
                        <div class="shoucang" @click="collect">
                            <i
                                :style="{color:Number(courseInfo.is_collect)===1?'#FD583E':'#bfbfbf'}"
                                class="fa icon-favorites-fill"
                            />&nbsp;收藏
                        </div>
                    </div>
                    <div class="learning-progress">
                        <div class="color-block" />
                        <div :style="{width:100 - Number(courseInfo.progress)+'%'}" class="zhezhao">
                            <div class="zhezhao-content" />
                        </div>
                        <div class="text-progress">{{ courseInfo.progress }}%</div>
                    </div>
                </div>

                <div class="switch-block">
                    <span class="select">章节</span>
                </div>
                <div :style="{height:(screenHeight - 220)+'px'}" class="content-s">
                    <PlayChapter v-if="currentSectionItem.id&&chapterData.length>0" :subdata="{courseInfo,chapterData:courseInfo.chapterData,currentSectionItem}" @startPlay="startPlay" />
                </div>
            </div>
            <div
                v-if="rightWidthVar===20"
                :style="{height:screenHeight+'px',lineHeight:screenHeight+'px',left:screenWidth-rightWidthVar+'px'}"
                class="right-block"
                style="width:20px;cursor:pointer"
                @click="rightWidthVar=350"
            >
                <i style="margin-left:2px;" class="safety-icon-doubleleft" />
            </div>
        </div>
    </div>
</template>

<script>
import videojs from 'video.js'
import PlayChapter from './components/playChapter.vue';
import 'video.js/dist/video-js.css'
export default {
    components:{
        PlayChapter,
    },
    filters:{
        pregress (val) {
            return `已学习${Math.floor(val * 100)}%`;
        },
        formatTime(duration){
            if(Number(duration) === 0){
                return '0秒';
            }
            let hour;
            let minute;
            let second;
            const allSeconds = Number(duration);
            // eslint-disable-next-line prefer-const
            hour = Math.floor(allSeconds / 3600);
            // eslint-disable-next-line no-mixed-operators
            minute = Math.floor(allSeconds % 3600 / 60);
            // eslint-disable-next-line prefer-const
            second = allSeconds % 60;
            minute < 10 && (minute = `0${minute}`);
            let temStr = '';
            Number(hour) !== 0 && (temStr += `${Number(hour)}小时`);
            Number(minute) !== 0 && (temStr += `${Number(minute)}分钟`);
            Number(second) !== 0 && (temStr += `${Number(second)}秒`);
            return temStr;
        },
    },
    data() {
        return {
            // 播放器实例
            player:'',
            // 屏幕实时尺寸
            screenWidth:'',
            screenHeight:'',
            courseInfo:{},
            // 右侧模块宽度
            rightWidthVar:350,
            // 章节列表
            chapterData: [],
            currentSectionItem:{},
            currentDefaultSectionItem:{},
            finishSectionId:'',
            allSections:0,
            finishedSections:0,
            currentProgress:0,
            // 跳转带来的sectionId
            itemId:'',

            normalStatusHeight:'',

            // 断点上传interval
            playInteval:'',
            loading:false,
        };
    },
    computed: {
        allTime(){
            let val = 0;
            if(this.chapterData.length > 0){
                this.chapterData.forEach(item => {
                    val += Number(item.course_duration);
                })
            }
            console.log('allTime',val);
            return val;
        }
    },
    watch: {
        rightWidthVar () {
            if(this.player !== ''){
                this.player.width(this.screenWidth - this.rightWidthVar);
            }
        },
        screenWidth () {
            if(this.player !== ''){
                this.player.width(this.screenWidth - this.rightWidthVar);
            }
        },
        screenHeight () {
            if(Number(this.courseInfo.type) !== 3 && this.player !== ''){
                this.player.height(this.screenHeight);
            }
        },
    },
    destroyed() {
        this.setInterval !== ''&&clearInterval(this.setInterval);
    },
    // eslint-disable-next-line consistent-return
    async mounted() {
        document.onfullscreenchange = function () {
            // 缩小
            if(!document.fullscreenElement){
                const tem = setTimeout(() => {
                    this.screenHeight = document.documentElement.clientHeight;
                    clearTimeout(tem);
                }, 100);
            }else{
                this.normalStatusHeight = this.screenHeight;
            }
        };
        this.screenWidth = this.$refs.content.scrollWidth;
        this.screenHeight = this.$refs.content.scrollHeight;
        if(this.$route.query.id){
            await this.getCourseInfo(this.$route.query.id);
        }else{
            this.$router.push('/course');
        }


        window.onresize = () => { // 定义窗口大小变更通知事件
            this.screenWidth = document.documentElement.scrollWidth; // 窗口宽度
            this.screenHeight = this.$refs.content.scrollHeight; // 窗口高度
        };
        if(Number(this.courseInfo.type) !== 3){
            return false;
        }
    },
    methods: {
        godetail(){
            window.location.href = `/courseDetail?id=${this.courseInfo.id}`;
        },
        // setProgress(){
        //     this.currentProgress = (this.finishedSections / this.allSections).toFixed(2);
        //     if(this.currentProgress > 1){
        //         this.currentProgress = 1;
        //     }
        // },

        // 断点上传
        duandianRemark(){
            const item = this.currentSectionItem;
            // 断点位置
            let duration;
            if(Number(item.study_time) >= Number(item.course_duration)){
                return false;
            }
            if(this.playInteval === ''){
                this.playInteval = setInterval(async() => {
                    const curduration = Math.ceil(this.player.currentTime());
                    if(curduration <= item.study_time){
                        return false;
                    }
                    if(curduration >= item.course_duration){
                        clearInterval(this.playInteval);
                        this.playInteval = '';
                        return false;
                    }
                    duration = curduration;
                    const params = {
                        course_id:this.courseInfo.id,
                        chapter_id: item.id,
                        study_time: duration
                    };
                    const res = await this.$http.post('/api/course/addCourseProgress',params);
                    if(res){
                        this.updateChapter(duration);
                        this.$set(this.courseInfo,'progress',Number(res.progress));
                        this.$forceUpdate();
                    }
                }, 10000);
            }
        },
        clearduanianRemark(){
            if(this.playInteval !== ''){
                clearInterval(this.playInteval);
                this.playInteval = '';
            }
        },
        changeSectionStatus(){
            const item = this.currentSectionItem;
            this.finishSectionId = item.id;
            // let duration;
            if(item.id){
                if(Number(item.finished) === 1){
                    return false;
                }
                // duration = item.duration;
                this.finishedSections += 1;
                
            }
        },
        
        startPlay(item){
            this.currentSectionItem = item;
            this.player.src(item.video_url);
            this.player.play();
            const tem = setTimeout(() => {
                if(Number(item.study_time) < Number(item.course_duration)){
                    this.player.currentTime(Number(item.study_time))
                }
                clearTimeout(tem);
            }, 500);
            this.$forceUpdate();
            
        },
        async collect() {
            const params = {
                id:this.$route.query.id,
                is_collect:1 - Number(this.courseInfo.is_collect),
            }
            const res = await this.$http.post('/api/course/userCollectCourse',params);
            if(res){
                this.$set(this.courseInfo,'is_collect',1 - Number(this.courseInfo.is_collect));
            }
        },
        switchblock(val){
            this.switchZ = val;
        },
        initPlayer(){
            const that = this;
            const options = {
                controls: true,
                language: 'zh-CN',
                width:that.screenWidth - that.rightWidthVar,
                height: that.screenHeight,
                controlBar:{
                    pictureInPictureToggle: false,
                },
            };
            // eslint-disable-next-line no-undef
            console.log(videojs);
            // eslint-disable-next-line no-undef
            that.player = videojs(this.$refs.player, options, function onPlayerReady() {
                that.afterInit(this);
            });
        },
        afterInit(player){
            const that = this;
            // 播放器已经准备好了
            player.src(that.currentSectionItem.video_url);
            player.play();

            // 使用事件监听
            player.on('pause', () => {
                that.clearduanianRemark();
            });
            player.on('play', () => {
                that.duandianRemark();
            });



            player.on('ended', async() => {
                if(Number(that.currentSectionItem.course_duration) > Number(that.currentSectionItem.study_time)){
                    const params = {
                        course_id: that.courseInfo.id,
                        chapter_id: that.currentSectionItem.id,
                        study_time: that.currentSectionItem.course_duration
                    };
                    const res = await this.$http.post('/api/course/addCourseProgress',params);
                    if(res){
                        this.updateChapter(that.currentSectionItem.course_duration);
                        this.$set(this.courseInfo,'progress',Number(res.progress));
                    }
                    return false;
                }
            });
        },

        updateChapter(duration){
            this.chapterData.forEach((item, index) => {
                if(item.id === this.currentSectionItem.id){
                    const item = JSON.parse(JSON.stringify(this.currentSectionItem));
                    item.study_time = duration;
                    this.$set(this.chapterData,index,item);
                    this.$forceUpdate();
                }
            })
        },



        async getCourseInfo(id) {
            const res = await this.$http.get('/api/course/getCourseDetail',{id});
            if(res){
                this.courseInfo = res;
                this.chapterData = res.chapter_list;
                this.currentSectionItem = this.chapterData[0];
                if(this.$route.query.itemId){
                    this.chapterData.forEach(item => {
                        if(Number(this.$route.query.itemId) === Number(item.id)){
                            this.currentSectionItem = item;
                        }
                    })
                }
                this.$forceUpdate();
                this.initPlayer();
            }
        },
        goMainPage(){
            window.location.href = '/course';
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep.educate-view .educate-view-header{
    display: none !important;
}
.learning-course{
    .learn{
        min-width: 1200px;
        min-height:712px;
        width:100%;
        height:100%;
        color:#fff;
        display: flex;
    }
}
.learning-course{
    color:#fff;
    width:100%;
    height:100%;
    // position: fixed;
    // top:0px;
    // z-index:2000;
    // left:0px;
    overflow: auto;
    .left-block{
        ::v-deep .el-scrollbar__wrap{
                overflow-x: auto;
            }
        box-sizing: border-box;
        display:inline-block;vertical-align: top;
        background-color: #000;
        padding: 30px 0px;
        img{
            margin-left:10%;
            width: 80%;
            display: block;
        }
    }
    .right-block{
        width:350px;
        background-color: #282828;
        .right-top{
            padding: 10px;
            box-sizing: border-box;
        }
        .guanbi{
            cursor: pointer;
            height:12px;font-size: 12px;font-weight: 500;position: relative;
            i{
                position: absolute;right:-3px;top:0px;
            }
        }
        .course-name{
            width: 97%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color:#fff;font-size: 16px;
            // margin-top: 15px;
            height:20px;cursor: pointer;
            &:hover{
                color:#4C85FF;
            }
        }
        .time-share-like{
            position: relative;color:#808080;font-size: 14px;
            font-weight: 400;height:20px;margin-top:22px;
            .share {
                text-align: center;
                position: absolute;
                right: 52px;
                top: 0px;
                width: 70px;
            }
            .shoucang {
                text-align: center;
                position: absolute;
                right: 0px;
                top: 0px;
                cursor: pointer;
            }
        }

        .learning-progress{
            margin-top: 25px;position: relative;
            .color-block{
                height:40px;border-radius:20px;width:100%;background: linear-gradient(to right,#4C85FF,#12DBED);border:0px;
            }
            .zhezhao{
                height:40px;background-color: #282828;position: absolute;top:0px;right:0px;overflow: hidden;
                .zhezhao-content{
                    position: absolute;right:0px;top:0px;z-index:9999;width: 330px;border-radius: 20px;
                    background-color: #333333;height:40px;
                }
            }
            .text-progress{
                width:100%;height:40px;line-height: 40px;text-align: center;font-weight:bold;
                position: absolute;top:0px;left:0px;font-size: 15px;z-index: 99999;
            }
        }

        .switch-block{
            display: none;
            height:40px;background-color: #333333;color:#808080;font-size: 0px;margin-top:20px;
            span{
                display: inline-block;vertical-align: middle;width: 100%;text-align: center;
                height: 38px;line-height: 38px;font-size: 14px;cursor: pointer;position: relative;


            }
            .select {
                    color: #0EC6C6;
                    position: relative;
                }
                .select::after {
                    content: '';
                    position: absolute;
                    height: 2px;
                    width: 100%;
                    bottom: -3px;
                    left: 0px;
                    background-color: #0EC6C6;
                }

        }

        .content-s{
            margin-top: 11px;
            overflow-y: auto;
        }
    }
}
</style>
