<template>
    <div class="chapter">
        <div v-for="(item,index) in subdata.courseInfo.chapter_list" :key="index" style="width:100%">
            <div 
                 :ref="item.id" 
                 :title="item.title" 
                 :class="subdata.currentSectionItem.id===item.id?'selectjie':''"
                 style="padding-right:90px;" 
                 class="commonC jie">
                {{ item.title }}
                <span :class="Number(item.study_time) >= Number(item.course_duration)?'finish-learn':''" class="jie-learning-time">{{ item|learningTime }}</span>
                <span class="start-learning" @click="goLearning(item)">开始学习</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    filters:{
        learningTime:function(val){
            if(Number(val.study_time) >= Number(val.course_duration)){
                return '已学习';
            }
            function formatTime(duration) {
                let hour;
                let minute;
                let second;
                const allSeconds = Number(duration);
                hour = Math.floor(allSeconds/3600);
                minute = Math.floor(allSeconds%3600/60);
                second = allSeconds%60;
                minute<10&&(minute='0'+minute);
                hour<10&&hour>0&&(hour='0'+hour+':');
                hour===0&&(hour='');
                hour>=10&&(hour+=':');
                second===0&&(second='00');
                second<10&&second>0&&(second='0'+second);
                return hour+minute+':'+second;
            }
            return formatTime(val.study_time)+'/'+formatTime(val.course_duration);

            
        }
    },
    props:{
        subdata:{
            type:Object,
            required:true,
        }
    },
    data() {
        return {
            chapterData:[],
            isInit:0,
            selectItem:'',
        };
    },
    watch: {
        'subdata.currentSectionItem.id'(){
            console.log(this.subdata.currentSectionItem.id)
        }
    },
    mounted() {
    },
    methods: {
        goLearning(item){
            this.$emit('startPlay',item);
        },
    },
};
</script>
<style lang="scss" scoped>
.chapter{
    width:100%;
    // padding-top: 22px;
    .commonC{
        height:30px;position: relative;line-height:30px;
        padding-right: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .zhang{
        color:#CCCCCC;font-size: 14px;
        padding-left: 10px;
        box-sizing: border-box;
        font-weight: bold;
        i{
            position: absolute;
            right: 10px;
            cursor: pointer;
        }
    }
    .jie{
        color:#808080;
        padding-left: 10px;
        box-sizing: border-box;
        font-size: 13px;
        padding-right: 91px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover{
            // background-color: #F3F9FF;
            color:#0EC6C6;
            .start-learning{
                display: block;
                cursor: pointer;
                font-size: 13px;
                font-weight: 400;
                top:4px;
            }
            
            .jie-learning-time{
                display: none;
            }

            .finish-learn{
                border: 1px solid #00CC00;
                height: 20px;
                line-height: 22px;
                width: 60px;
                color: #00CC00;
                border-radius: 3px;
                text-align: center;
                top: 4px;
            }
        }
        i{
            color:#4C85FF;font-size: 16px;
        }

        .isStarting{
                width:60px;
                height:20px;
                line-height: 20px;
                text-align: center;
                border:1px solid rgba(0,204,0,1);
                border-radius:3px;
                color:#00cc00;
                top:13px;
            }

        .jie-learning-time{
            position: absolute;
            right:10px;
            font-size: 13px;
            font-weight: 400;
        }
        .finish-learn{
                border: 1px solid #00CC00;
                height: 20px;
                line-height: 22px;
                width: 60px;
                color: #00CC00;
                border-radius: 3px;
                text-align: center;
                top: 4px;
            }
        .start-learning{
            display: inline-block;
            width:70px;
            height:20px;
            background:rgba(76,133,255,1);
            border-radius:15px;
            font-size: 12px;
            font-weight: bold;
            color:#fff;
            text-align: center;
            line-height: 22px;
            position: absolute;
            top:4px;
            right:10px;
            display: none;
        }
    }

    .selectjie{
        background-color: rgb(51, 51, 51) !important;
        color: rgb(14, 198, 198) !important;
    }

    .selected{
        background-color: #282828;
    }
}
</style>


